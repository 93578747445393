/** @jsx jsx */
import { graphql } from "gatsby"
import { jsx } from "theme-ui"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import Container from "../components/container"
import PersonneCollection from "../components/personne-collection"
import PersonneItem from "../components/personne-item"

export default ({ data }) => (
  <Layout>
    <SEO title="Réalisateurs" />
    <Helmet>
      <link rel="canonical" href={"https://lesfilmspelleas.com/realisateur/"} />
    </Helmet>
    <Section.Title1 sx={{ mt: 5, mb: 4 }}>CINÉASTES</Section.Title1>
    <Section isGrey={false}>
      <Container isFull={true}>
        <PersonneCollection>
          {data.realisateurs.nodes.map(
            personne =>
              personne.Image_principale_optimized && (
                <PersonneItem
                  isFluid={true}
                  key={personne.id}
                  personne={personne}
                />
              )
          )}
        </PersonneCollection>
      </Container>
    </Section>
  </Layout>
)

export const query = graphql`
  query {
    realisateurs: allBubblePersonne(
      filter: {
        isReal: { eq: true }
        DELETED: { ne: true }
        # PUBLIE: {eq: true},
      }
      sort: { fields: CACHE_Nom_tri }
    ) {
      nodes {
        Image_principale_optimized {
          childImageSharp {
            fluid(maxWidth: 170, maxHeight: 170, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        CACHE_Nom_complet
        id
        slug
      }
    }
  }
`
